import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setUserLogged, setUserRol } from "../../../../reducers/userConfigSlice";
//Components
import { Input } from "../../../../components/inputs/type1";
import { GoMail } from "react-icons/go";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
//Assets
//Api
//import { login } from "../../../../api/login";

export const FormLogin = ({ callback }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = useState(false);

  const handleLogin = async () => {
    /*const email = document.getElementById("emailInput").value;
        const password = document.getElementById("passwordInput").value;
        const data = {
          web: window.location.host === "localhost:3010" ? "delibran.com" : window.location.host,
          email: email,
          password: password,
        };
        const response = await login(data);
        if (response && response.length > 0) {
          console.log(response);
          if (response[0].rol === "admin") {
            dispatch(setUserLogged(true));
            dispatch(setUserRol(response[0].rol));
            navigate("/admin");
          } else {
            dispatch(setUserLogged(true));
            dispatch(setUserRol(response[0].rol));
            navigate("/client");
          }
          setLoginFailed(false);
        } else {
          setLoginFailed(true);
        }*/

    dispatch(setUserLogged(true));
    dispatch(setUserRol("admin"));
    navigate("/userSelect");
  };

  return (
    <div className="w100 p-2">
      {/*<p className="labelLogin">Email</p>
      <Input
        id={"emailInput"}
        type={"text"}
        placeholder=""
        disabled={""}
        onKeyPress={() => null}
        icon={<GoMail />}
        classname={loginFailed ? "errorValueInput" : ""}
      />*/}
      <p className="labelLogin">Contraseña</p>
      <Input
        id={"passwordInput"}
        type={"password"}
        placeholder=""
        disabled={""}
        onKeyPress={() => null}
        icon={<AiOutlineEyeInvisible />}
        iconType={"tooglePasswordVisibility"}
        classname={loginFailed ? "errorValueInput" : ""}
      />
      {loginFailed ? (
        <div className="errorValue errorMessage">
          <RiErrorWarningFill /> {"El usuario o la contraseña no son correctos"}
        </div>
      ) : (
        ""
      )}

      {/*<div className="flex infoLoginPass mt-1">
        <div className="w-50">
          <div className="flex-start" style={{ alignItems: "center" }}>
            <input type="checkbox" /> <div style={{ paddingLeft: "10px" }}> Recordar contraseña</div>
          </div>
        </div>
        <div className="forgetPass w-50" onClick={callback}>
          He olvidado mi contraseña
        </div>
      </div>*/}
      <button className="button-1 mt-3" onClick={handleLogin}>
        Iniciar sesión
      </button>
    </div>
  );
};
