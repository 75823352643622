export const ordersMock = [
  {
    id: 1,
    name: "John Doe",
    phone: "123-456-7890",
    time: 45,
    code: "A1B2C3",
    quantity: 2,
    price: 19.99,
    state: 1,
  },
  {
    id: 2,
    name: "Jane Smith",
    phone: "234-567-8901",
    time: 30,
    code: "D4E5F6",
    quantity: 3,
    price: 29.99,
    state: 1,
  },
  {
    id: 3,
    name: "Emily Johnson",
    phone: "345-678-9012",
    time: 60,
    code: "G7H8I9",
    quantity: 1,
    price: 9.99,
    state: 0,
  },
  {
    id: 4,
    name: "Michael Brown",
    phone: "456-789-0123",
    time: 25,
    code: "J1K2L3",
    quantity: 4,
    price: 39.99,
    state: 1,
  },
  {
    id: 5,
    name: "Jessica Davis",
    phone: "567-890-1234",
    time: 50,
    code: "M4N5O6",
    quantity: 2,
    price: 19.99,
    state: 0,
  },
  {
    id: 6,
    name: "David Wilson",
    phone: "678-901-2345",
    time: 70,
    code: "P7Q8R9",
    quantity: 3,
    price: 29.99,
    state: 1,
  },
  {
    id: 7,
    name: "Linda Martinez",
    phone: "789-012-3456",
    time: 55,
    code: "S1T2U3",
    quantity: 1,
    price: 9.99,
    state: 1,
  },
  {
    id: 8,
    name: "Chris Garcia",
    phone: "890-123-4567",
    time: 80,
    code: "V4W5X6",
    quantity: 2,
    price: 19.99,
    state: 0,
  },
];
