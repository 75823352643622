import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setUserLogged, setUserRol } from "../../../../reducers/userConfigSlice";
//Components
import { Input } from "../../../../components/inputs/type1";
import { GoMail } from "react-icons/go";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { RiErrorWarningFill } from "react-icons/ri";
//Assets
//Api
//import { newAccount } from "../../../../api/login";

export const FormRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = useState(false);

  const handleNewAccount = async () => {
    /*const email = document.getElementById("emailInput").value;
        const password = document.getElementById("passwordInput").value;
        const data = {
          web: window.location.host === "localhost:3010" ? "delibran.com" : window.location.host,
          email: email,
          password: password,
        };
        const response = await newAccount(data);
        if (response && response.length > 0) {
          console.log(response);
          if (response[0].rol === "admin") {
            dispatch(setUserLogged(true));
            dispatch(setUserRol(response[0].rol));
            navigate("/admin");
          } else {
            dispatch(setUserLogged(true));
            dispatch(setUserRol(response[0].rol));
            navigate("/client");
          }
          setLoginFailed(false);
        } else {
          setLoginFailed(true);
        }*/
    return true;
  };

  return (
    <div className="w33">
      <p className="labelLogin">Nombre</p>
      <Input
        id={"nameInput"}
        type={"text"}
        placeholder=""
        disabled={""}
        onKeyPress={() => null}
        icon={<GoMail />}
        classname={loginFailed ? "errorValue" : ""}
      />
      <p className="labelLogin">Email</p>
      <Input
        id={"emailInput"}
        type={"text"}
        placeholder=""
        disabled={""}
        onKeyPress={() => null}
        icon={<GoMail />}
        classname={loginFailed ? "errorValue" : ""}
      />
      <p className="labelLogin">Contraseña</p>
      <Input
        id={"passwordInput"}
        type={"password"}
        placeholder=""
        disabled={""}
        onKeyPress={() => null}
        icon={<AiOutlineEyeInvisible />}
        iconType={"tooglePasswordVisibility"}
        classname={loginFailed ? "errorValue" : ""}
      />
      {loginFailed ? (
        <div className="errorValue errorMessage">
          <RiErrorWarningFill /> {"Contraseña o email incorrecto"}
        </div>
      ) : (
        ""
      )}

      <button className="button-1" onClick={handleNewAccount}>
        Registrarse
      </button>
    </div>
  );
};
