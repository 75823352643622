import { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive, setUserLogOut } from "../../reducers/userConfigSlice";
//Components
import { GoHomeFill, GoInbox, GoPerson } from "react-icons/go";
import { PiPackageLight, PiMoney } from "react-icons/pi";
import { LiaBarsSolid } from "react-icons/lia";
import { ImCog } from "react-icons/im";
import { BsBarChart } from "react-icons/bs";
import { IoExitOutline } from "react-icons/io5";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { FiShoppingBag } from "react-icons/fi";
//Styles
import "./style.css";

export const MenuMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);
  const [menuMobile, showMenuMobile] = useState(false);

  const logOff = async () => {
    dispatch(setUserLogOut());
    navigate("/");
  };

  const optionsMenuMobile = [
    { id: 1, name: "Clientes", icon: <GoPerson />, component: "" },
    { id: 1, name: "Descuentos", icon: <GoPerson />, component: "" },
    { id: 1, name: "Informes y estadísticas", icon: <BsBarChart />, component: "" },
    { id: 1, name: "Pagos", icon: <PiMoney />, component: "" },
    { id: 1, name: "Configuración", icon: <ImCog />, component: "" },
  ];

  const handleMenu = (component) => {
    if (component === "logout") logOff();
    else {
      dispatch(setComponentActive(component));
      navigate("/" + component);
    }
  };

  const handleMobileMenu = () => {
    console.log("handleMobileMenu");
    showMenuMobile(!menuMobile);
  };

  const BadgeMenuMobile = () => {
    return (
      <div className="badgeMenuMobile">
        {/*<div className="closeBadgeMenuMobile">
          <div onClick={() => handleMobileMenu()}></div>
    </div>*/}
        <div style={{ textAlign: "end", cursor: "pointer", padding: "0px 23px" }}>
          <IoExitOutline onClick={() => handleMobileMenu()} />
        </div>
        {optionsMenuMobile.map((menu) => {
          return (
            <div>
              {menu.icon} <label>{menu.name}</label>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="bottom-toolbar">
      <div>
        <MdOutlineDeliveryDining
          className={componentActive === "delivery" ? " optionMenuMobileActive " : ""}
          onClick={() => handleMenu("delivery")}
        />
      </div>
      <div>
        <FiShoppingBag className={componentActive === "orders" ? " optionMenuMobileActive " : ""} onClick={() => handleMenu("orders")} />
      </div>
      <div>
        <IoExitOutline
          className={componentActive === "logout" ? " optionMenuMobileActive " : ""}
          size={"1.7rem"}
          onClick={() => handleMenu("logout")}
        />
      </div>
      {/*<div>
        <LiaBarsSolid className={menuMobile ? " optionMenuMobileActive " : ""} onClick={() => handleMobileMenu()} />
      </div>*/}
      {/*menuMobile && (
        <>
          <div className="shadowBackground"></div>
          <BadgeMenuMobile />
        </>
      )*/}
    </div>
  );
};
