import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { MapLocation } from "../../components/map";
import { FaBox } from "react-icons/fa";
import { OrderDetailedResumed } from "../../components/orderDetailedResumed";
import { Button } from "../../components/button";
import { IoMdClose } from "react-icons/io";

export const OrderResumedMap = () => {
  const order = useSelector((state) => state.aplicationConfig.userConfig.order);
  const navigate = useNavigate();

  const handleNavigation = (component) => {
    console.log("handleNavigation");
    navigate("/" + component);
  };

  const openMap = (lat, lon) => {
    const url = `geo:${lat},${lon}`;
    window.location.href = url;
  };

  return (
    <div>
      <div className="backgroundContent">
        <MapLocation lat={37.408887} lon={-4.485055} />
      </div>
      <div className="orderFloat">
        <div className="how-go">
          <Button text={"Como llegar"} icon={<FaBox />} customClass={"customButtonColor1"} action={() => openMap(37.408887, -4.485055)} />
        </div>
        <OrderDetailedResumed data={order} />
      </div>
      <IoMdClose className="action-close" onClick={() => handleNavigation("delivery")} />
    </div>
  );
};
