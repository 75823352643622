import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { OrderDetailed } from "../../components/orderDetailed";
import { IoMdClose } from "react-icons/io";
import "./style.css";
import { MapLocation } from "../../components/map";

export const OrderMap = () => {
  const order = useSelector((state) => state.aplicationConfig.userConfig.order);
  const [loc, setLoc] = useState({ lat: null, lon: null });
  const navigate = useNavigate();

  const handleNavigation = (component) => {
    console.log("handleNavigation");
    navigate("/" + component);
  };

  const getLoc = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          setLoc({ lat: lat, lon: lon });
        },
        function (error) {
          console.error("Error obteniendo la geolocalización: ", error);
          setLoc({ lat: null, lon: null });
        }
      );
    } else {
      console.log("Geolocalización no está disponible en este navegador.");
      setLoc({ lat: null, lon: null });
    }
  };

  useEffect(() => {
    getLoc();
  }, []);

  return (
    <div>
      <div className="backgroundContent">
        <MapLocation lat={loc.lat} lon={loc.lon} />
      </div>
      <div className="orderFloat">
        <OrderDetailed data={order} />
      </div>
      <IoMdClose className="action-close" onClick={() => handleNavigation("orders")} />
    </div>
  );
};
