import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./../../../assets/logo/logo.jpg";
import { LuUser2 } from "react-icons/lu";
import "./style.css";

export const UserSelect = () => {
  const navigate = useNavigate();
  const userList = [
    { id: 0, name: "Alberto" },
    { id: 1, name: "Sara" },
  ];

  const handleUser = async () => {
    navigate("/pinSelect");
  };

  const CardUser = ({ data }) => {
    return (
      <div className="cardUser" onClick={handleUser}>
        <div className="userIcon">
          <LuUser2 />
        </div>
        <div className="username">{data.name}</div>
      </div>
    );
  };

  return (
    <div className="loginArea">
      <div className="topSide">
        <div className="logoContainer">
          <img src={Logo} alt="logo" className="logoLogin" />
        </div>
        <div className="titleLogin">Seleccione un usuario</div>
      </div>
      <div className="userList">
        {userList.map((user) => (
          <CardUser data={user} />
        ))}
      </div>
    </div>
  );
};
