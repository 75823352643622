import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setOrder } from "../../reducers/userConfigSlice";
import { Button } from "./../../components/button";
import { FiShoppingBag } from "react-icons/fi";
import "./style.css";

export const PaymentDone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (component, order = null) => {
    order !== null && dispatch(setOrder(order));
    navigate("/" + component);
  };
  return (
    <div className="paymentDone">
      <div className="paymentresponse">
        <div className="paymentIcon">
          <FiShoppingBag />
        </div>
        <div className="paymentTitle">¡Pedido entregado!</div>
        <div className="paymentDescription">El pedido ha sido entregado con éxito.</div>
      </div>
      <div className="paymentDoneAction">
        <Button text={"Volver a pedidos"} icon={null} customClass={""} action={() => handleNavigation("delivery")} />
      </div>
    </div>
  );
};
