//Components
import { OrderDetailed } from "../../components/orderDetailed";
import { ordersMock } from "./mock";

import "./style.css";

export const Orders = () => {
  return (
    <div className="ordersContainer">
      {ordersMock.map((order) => (
        <OrderDetailed data={order} />
      ))}
    </div>
  );
};
