import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setOrder } from "../../reducers/userConfigSlice";
//Components
import { OrderDetail, OrderDetailResumed } from "../orderDetail";
import { LuClock10 } from "react-icons/lu";
import { FiShoppingBag } from "react-icons/fi";
import { PiPackage } from "react-icons/pi";
import { Button } from "../button";
import "./style.css";
import { StateLabel } from "../stateLabel";

export const OrderDetailedResumed = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (component, order = null) => {
    order !== null && dispatch(setOrder(order));
    navigate("/" + component);
  };

  const DataResumed = ({ data }) => {
    return (
      <div className="dataResumed">
        <div className="dataResumedItem">
          <LuClock10 size={"1.1rem"} /> {data.time} min
        </div>
        <div className="dataResumedItem">
          <FiShoppingBag size={"1.1rem"} /> {data.code}
        </div>
        <div className="dataResumedItem">
          <PiPackage size={"1.3rem"} /> {data.quantity}
        </div>
      </div>
    );
  };

  const OrderResumedInfo = ({ data }) => {
    switch (data.state) {
      case 0:
        return (
          <>
            <div className="order-resumed-state-pending">
              <div>{data.price} €</div>
            </div>
            <div className="order-resumed-state-pending">Pendiente</div>
          </>
        );
      case 1:
        return (
          <>
            <div className="order-resumed-state-success">
              <div>{data.price} €</div>
            </div>
            <div className="order-resumed-state-success">Pagado</div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <div className="containerBlock">
      <div className="order-header cursor" onClick={() => handleNavigation("orderResumedMap", data)}>
        <div className="order-avatar"></div>
        <div className="order-userData">
          <div className="order-userDataName">{data.name}</div>
          <div className="order-userDataPhone">{data.phone}</div>
        </div>
      </div>
      <OrderDetailResumed content={<DataResumed data={data} />} className="order-row-last" />
      <div className="order-resumed-footer">
        <div className="order-resumed-footer-info">
          <OrderResumedInfo data={data} />
        </div>
        <div className="order-resumed-footer-action">
          <Button text={"Entregar pedido"} icon={null} customClass={"customButtonGreen"} action={() => handleNavigation("payment", data)} />
        </div>
      </div>
    </div>
  );
};
