import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setOrder } from "../../reducers/userConfigSlice";
//Components
import { OrderDetail } from "./../orderDetail";
import { CiMap } from "react-icons/ci";
import { LiaArrowAltCircleRight } from "react-icons/lia";
import { LuClock10 } from "react-icons/lu";
import { FiShoppingBag } from "react-icons/fi";
import { PiPackage } from "react-icons/pi";
import { AiOutlineEuro } from "react-icons/ai";
import { Button } from "../button";

export const OrderDetailed = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (component, order = null) => {
    order !== null && dispatch(setOrder(order));
    navigate("/" + component);
  };
  return (
    <div className="containerBlock">
      <div className="order-header">
        <div className="order-avatar"></div>
        <div className="order-userData">
          <div className="order-userDataName">{data.name}</div>
          <div className="order-userDataPhone">{data.phone}</div>
        </div>
      </div>
      <OrderDetail text="Tiempo de entrega" value={data.time} icon={<LuClock10 size={"1.1rem"} />} ext={"min"} />
      <OrderDetail text="No. Pedido" value={data.code} icon={<FiShoppingBag size={"1.1rem"} />} />
      <OrderDetail text="No. Productos" value={data.quantity} icon={<PiPackage size={"1.3rem"} />} />
      <OrderDetail
        text="Total del pago"
        value={data.price}
        label={data.state}
        icon={<AiOutlineEuro size={"1.3rem"} />}
        ext={"€"}
        className="order-row-last"
      />
      <div className="order-footer">
        <div className="order-helpers">
          <LiaArrowAltCircleRight />
          <CiMap onClick={() => handleNavigation("orderMap", data)} />
          <Button text={"Detalles"} icon={null} customClass={""} action={() => handleNavigation("orderDetails", data)} />
        </div>
        <div className="order-action">
          <button>Iniciar entrega</button>
        </div>
      </div>
    </div>
  );
};
