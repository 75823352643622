import { useState } from "react";
import "./style.css";

export const Input = ({
  id,
  disabled,
  placeholder,
  onKeyPress,
  type = "text",
  icon,
  iconType = null,
  classname = "",
  iconPositionRight = true,
  value,
}) => {
  const [inputType, setInputType] = useState(type);

  const handleIcon = () => {
    if (iconType === "tooglePasswordVisibility") {
      inputType === "text" ? setInputType("password") : setInputType("text");
    }
  };

  const iconAction = iconType === null ? "" : " actionIcon ";
  const inputSpaceAtStart = iconPositionRight ? "" : " inputSpaceAtStart ";

  return (
    <div className="input-container">
      {!iconPositionRight && (
        <div className={" iconLeft " + iconAction} onClick={handleIcon}>
          {icon}
        </div>
      )}
      <input
        id={id}
        value={value}
        type={inputType}
        placeholder={placeholder}
        disabled={disabled}
        onKeyPress={onKeyPress}
        className={`${classname} ${inputSpaceAtStart} ${iconPositionRight ? "" : "inputSpaceAtStart"}`}
      />
      {iconPositionRight && (
        <div className={" inputRight " + iconAction} onClick={handleIcon}>
          {icon}
        </div>
      )}
    </div>
  );
};
