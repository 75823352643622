import { useNavigate } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
//Components
import { GoCopy } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import "./style.css";

export const OrderDetails = () => {
  const navigate = useNavigate();
  const order = useSelector((state) => state.aplicationConfig.userConfig.order);

  const mockProducts = [
    { id: 0, name: "Hamburguesa clásica", description: "Lorep imsum sit amet consetuer adiption", price: "25,00", quantity: 1 },
    { id: 1, name: "Coca Cola", description: "Lorep imsum sit amet consetuer adiption", price: "2,00", quantity: 2 },
  ];

  const handleMenu = (component) => {
    navigate("/" + component);
  };

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Texto copiado al portapapeles");
      })
      .catch((err) => {
        console.log("Error al copiar: ", err);
      });
  }

  const Product = ({ data }) => {
    return (
      <div className="productItem">
        <div className="productItem-image"></div>
        <div className="productItem-details">
          <div className="productItem-details-block1">
            <div className="productItem-name">{data.name}</div>
            <div className="productItem-description">{data.description}</div>
          </div>
          <div className="productItem-details-block2">
            <div className="productItem-info">
              <div className="productItem-price">{data.price}€</div>
              <div className="productItem-quantity">{data.quantity}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const InvoiceResume = () => {
    return (
      <div>
        <div className="invoiceResume-Body">
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Subtotal</div>
            <div className="invoiceResume-note">2 artículos</div>
            <div className="invoiceResume-value">49,98€</div>
          </div>
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Domicilio</div>
            <div className="invoiceResume-note"></div>
            <div className="invoiceResume-value">2,00€</div>
          </div>
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Descuento</div>
            <div className="invoiceResume-note">X9FJBZ8P</div>
            <div className="invoiceResume-value">-10,99€</div>
          </div>
          <div className="invoiceResume-Separator"></div>
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Total</div>
            <div></div>
            <div className="invoiceResume-value">38,99€</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="order-details">
      <div className="order-details-header">
        <div className="order-details-date">23 de Junio</div>
        <IoMdClose size={"1.2rem"} onClick={() => handleMenu("orders")} />
      </div>
      <div className="order-details-title">Detalles del pedido</div>

      <div className="containerBlock">
        <div className="order-details-titleBlock">Productos del pedido</div>
        <div className="order-details-list">
          {mockProducts.map((product) => (
            <Product data={product} />
          ))}
        </div>
      </div>

      <div className="containerBlock">
        <div className="order-details-titleBlock">Total</div>
        <InvoiceResume />
      </div>

      <div className="containerBlock">
        <div className="order-details-titleBlock">Cliente</div>
        <div className="order-details-client-title">Nombre del cliente</div>
        <div className="order-details-client-data">Miguel Ángel Hernandez</div>
        <div className="order-details-client-title">Información de contacto</div>
        <div className="order-details-client-data">
          +34 686 20 20 20 <GoCopy onClick={() => copyToClipboard("+34 686 20 20 20")} />
        </div>
        <div className="order-details-client-title">Dirección de envío</div>
        <div className="order-details-client-data">
          Calle Barcelona 45 3ºB, 14900, Lucena <GoCopy onClick={() => copyToClipboard("Calle Barcelona 45 3ºB, 14900, Lucena")} />
        </div>
      </div>

      <div className="containerBlock">
        <div className="order-details-titleBlock">Notas</div>
        <div className="order-details-notes">Sin notas del pedido</div>
      </div>
    </div>
  );
};
