import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Sections
import Login from "./pages/login";
import { Orders } from "./pages/orders";
import { ProtectedRoute } from "./components/security/protectedRoute";
import { MenuMobile } from "./components/menuMobile";
import { OrderMap } from "./pages/orderMap";
import { PinSelect } from "./pages/login/userSelect/pin";
import { UserSelect } from "./pages/login/userSelect";
import { OrderDetails } from "./pages/orderDetails";
import { OrdersDelivered } from "./pages/ordersDelivered";
import { OrderResumedMap } from "./pages/orderResumedMap";
import { PaymentDone } from "./pages/paymentDone";
import { Payment } from "./pages/payment";
//Functions
//import useLanguage from "./functions/useLanguage";

function App() {
  //useLanguage();

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/userSelect"
            element={
              <ProtectedRoute page="userSelect">
                <UserSelect />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pinSelect"
            element={
              <ProtectedRoute page="pinSelect">
                <PinSelect />
              </ProtectedRoute>
            }
          />
          <Route
            path="/delivery"
            element={
              <ProtectedRoute page="delivery">
                <MenuMobile />
                <OrdersDelivered />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute page="orders">
                <MenuMobile />
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orderMap"
            element={
              <ProtectedRoute page="orderMap">
                <MenuMobile />
                <OrderMap />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orderDetails"
            element={
              <ProtectedRoute page="orderDetails">
                <MenuMobile />
                <OrderDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orderResumedMap"
            element={
              <ProtectedRoute page="orderResumedMap">
                <MenuMobile />
                <OrderResumedMap />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment"
            element={
              <ProtectedRoute page="payment">
                <MenuMobile />
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/paymentDone"
            element={
              <ProtectedRoute page="paymentDone">
                <MenuMobile />
                <PaymentDone />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
