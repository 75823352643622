import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setOrder } from "../../reducers/userConfigSlice";
import { Button } from "./../../components/button";
import { Input } from "../../components/inputs/type1";
import { CiCreditCard1, CiMoneyBill } from "react-icons/ci";
import "./style.css";

export const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (component, order = null) => {
    order !== null && dispatch(setOrder(order));
    navigate("/" + component);
  };

  const Card = ({ data }) => {
    return (
      <div className="cardPayment" onClick={() => null}>
        {data.icon}
        {data.name}
      </div>
    );
  };

  return (
    <div className="paymentContainer">
      <div className="paymentTotal">
        <div className="paymentTotalTitle">Ángel Gonzalez</div>
        <div className="paymentTotalValue">52,40 €</div>
      </div>

      <div className="paymentMethods">
        <div className="paymentMethodsList">
          <Card data={{ name: "Crédito", icon: <CiCreditCard1 /> }} />
          <Card data={{ name: "Efectivo", icon: <CiMoneyBill /> }} />
        </div>
        <div className="paymentMethodsValue">
          <div className="paymentMethodsValueTitle">Ingresa el valor</div>
          <div className="paymentMethodsValueInput">
            <Input
              id={"inputPaymentInput"}
              type={"text"}
              placeholder=""
              disabled={""}
              onKeyPress={() => null}
              icon={null}
              iconType={""}
              classname={""}
            />
          </div>
        </div>
      </div>

      <div className="paymentAction">
        <div className="paymentInfo">
          <div className="paymentInfoTitle">Cambio</div>
          <div className="paymentInfoValue">7,60 €</div>
        </div>
        <Button text={"Entregar pedido"} icon={null} customClass={"customButtonGreen w-100"} action={() => handleNavigation("paymentDone")} />
      </div>
    </div>
  );
};
