//Components
import { OrderDetailedResumed } from "../../components/orderDetailedResumed";
import { ordersMock } from "./mock";
import "./style.css";

export const OrdersDelivered = () => {
  return (
    <div className="ordersContainer">
      {ordersMock.map((order) => (
        <OrderDetailedResumed data={order} />
      ))}
    </div>
  );
};
