import "./style.css";

const DataLabel = ({ state }) => {
  console.log(state);
  switch (state) {
    case 0:
      return <div className="order-dataLabel order-dataLabel-pending">Pendiente</div>;
    case 1:
      return <div className="order-dataLabel order-dataLabel-success">Pagado</div>;
    default:
      break;
  }
};

export const OrderDetail = ({ text, value, label = null, icon, ext = "", className = "" }) => {
  return (
    <div className={"order-row " + className}>
      <div className="order-row-info">
        <div className="order-icon">{icon}</div>
        <div className="order-data">
          <div className="order-dataText">{text}</div>
          <div className="order-dataValue">
            {value} {ext}
          </div>
        </div>
      </div>
      <div className="order-row-action">{label !== null && <DataLabel state={label} />}</div>
    </div>
  );
};

export const OrderDetailResumed = ({ content, className = "" }) => {
  return <div className={"order-row " + className}>{content}</div>;
};
